<!-- Start Why Choose Us Area -->
<section class="why-choose-us-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="choose-us-content ptb-100">
                    <span class="top-title">Why Choose Us</span>
                    <h2>We Are Award Winning Company</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint cupiditate, dolorem odio quia mollitia deleniti at error ratione qui vero ex provident pariatur</p>
                    <ul>
                        <li><i class="flaticon-tick"></i> 25 Years of Experience</li>
                        <li><i class="flaticon-tick"></i> Save You Money</li>
                        <li><i class="flaticon-tick"></i> A Fast & Easy Application</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support</li>
                        <li><i class="flaticon-tick"></i> Growing Your Business</li>
                    </ul>
                    <a routerLink="/about-us" class="default-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="why-choose-slider-content">
                    <div class="why-choose-us-slider">
                        <owl-carousel-o [options]="whyChooseUsSliderOptions">
                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-1"></div>
                            </ng-template>

                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-2"></div>
                            </ng-template>

                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-3"></div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="success">
                        <span>100%</span>
                        <h3>Success Rate</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->