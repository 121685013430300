<!-- Start Partner Area -->
<div class="partner-area bg-color ptb-70">
    <div class="container">
        <div class="partner-slider">
            <owl-carousel-o [options]="partnerSlidesOptions">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner/partner-1.png" alt="Image">
                        </a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner/partner-2.png" alt="Image">
                        </a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner/partner-3.png" alt="Image">
                        </a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner/partner-4.png" alt="Image">
                        </a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner/partner-5.png" alt="Image">
                        </a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
<!-- End Partner Area -->