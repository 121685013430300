<!-- Start Page Title Area -->
<div class="page-title-area bg-7">
    <div class="container">
        <div class="page-title-content">
            <h2>Claims</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Claims</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Claims Area -->
<section class="claims-area pt-100 pb-70">
    <div class="container">
        <div class="claims-content">
            <div class="content step-1">
                <span>Claims</span>
                <h2>What Would You Like to Claim For?</h2>
                <div class="claims-img">
                    <img src="assets/img/claims-img.jpg" alt="Image">
                </div>
            </div>

            <div class="content step-2">
                <h3>Reporting a Claim</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.</p>
            </div>

            <div class="content step-3">
                <h3>Who is Handling My Claim?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <ul>
                    <li>At vero eos et accusam et justo duo dolores et ea rebum.</li>
                </ul>
                <ul>
                    <li>Stet clita kasd gubergren, no sea takimata sanctus est ipsum dolor sit amet.</li>
                </ul>
                <ul>
                    <li>Takimata sanctus est sed diam nonumy eirmod tempor invidunt</li>
                </ul>
                <ul>
                    <li>Labore et dolore magna aliquyam erat, sed diam voluptua.</li>
                </ul>
                <ul>
                    <li>Stet clita kasd gubergren, no sea takimata sanctus.</li>
                </ul>
            </div>

            <div class="content step-3">
                <h3>What You need to Make a Claim</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <ul>
                    <li>At vero eos et accusam et justo duo dolores et ea rebum.</li>
                </ul>
                <ul>
                    <li>Stet clita kasd gubergren, no sea takimata sanctus est ipsum dolor sit amet.</li>
                </ul>
                <ul>
                    <li>Takimata sanctus est sed diam nonumy eirmod tempor invidunt</li>
                </ul>
                <ul>
                    <li>Labore et dolore magna aliquyam erat, sed diam voluptua.</li>
                </ul>
                <ul>
                    <li>Stet clita kasd gubergren, no sea takimata sanctus.</li>
                </ul>
            </div>

            <div class="content step-4">
                <h3>General Claims</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.</p>
            </div>

            <div class="content step-5">
                <h3>Start Your Claim, We're Ready to Help You!</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="contact-us">
                            <span>Phone</span>
                            <a href="tel:+1-(514)-312-6677">+1 (514) 312-6677</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="contact-us">
                            <span>Email</span>
                            <a href="mailto:info@visiondentalnow.com">info@visiondentalnow.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Claims Area -->