<!-- Start Page Title Area -->
<div class="page-title-area bg-6">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing Tables</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Table Area -->
<section class="pricing-table-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-box active">
                    <div class="pricing-title">
                        <span>Vision Single</span>
                        <h1>12<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Superior Service</li>
                        <li><i class="bx bx-check"></i>Affordable Rates</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                    </ul>
                    <a href="https://buy.stripe.com/test_5kAg0Z4ZL7wdbJedQR" class="default-btn">Book Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-box active">
                    <div class="pricing-title">
                        <span>Vision Single +1</span>
                        <h1>22<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Superior Service</li>
                        <li><i class="bx bx-check"></i>Affordable Rates</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                    </ul>
                    <a href="https://buy.stripe.com/test_14keWV1Nz03L4gM8wy" class="default-btn">Book Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-box active">
                    <div class="pricing-title">
                        <span>Vision Family</span>
                        <h1>30<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Superior Service</li>
                        <li><i class="bx bx-check"></i>Affordable Rates</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                    </ul>
                    <a href="https://buy.stripe.com/test_dR6eWVgIt5o5eVq8wz" class="default-btn">Book Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-box active">
                    <div class="pricing-title">
                        <span>Dental Single</span>
                        <h1>22<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Superior Service</li>
                        <li><i class="bx bx-check"></i>Affordable Rates</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                    </ul>
                    <a href="https://buy.stripe.com/test_3cscONgIt4k13cI4gk" class="default-btn">Book Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-box active">
                    <div class="pricing-title">
                        <span>Dental Single +1</span>
                        <h1>38<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Superior Service</li>
                        <li><i class="bx bx-check"></i>Affordable Rates</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                       
                    </ul>
                    <a href="https://buy.stripe.com/test_dR6bKJ9g13fX8x29AF" class="default-btn">Book Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing-box active">
                    <div class="pricing-title">
                        <span>Dental Family</span>
                        <h1>62<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Superior Service</li>
                        <li><i class="bx bx-check"></i>Affordable Rates</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                        <li><i class="bx bx-check"></i>Information</li>
                    </ul>
                    <a href="https://buy.stripe.com/test_cN23edbo97wd8x24gm" class="default-btn">Book Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Table Area -->