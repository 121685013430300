<!-- Start Page Title Area -->
<div class="page-title-area bg-20">
    <div class="container">
        <div class="page-title-content">
            <h2>Recover Password</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Recover Password</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Recover Password Area -->
<section class="user-area-style recover-password-area ptb-100">
    <div class="container">
        <div class="contact-form-action recover">
            <div class="form-heading text-center">
                <h3 class="form-title">Reset Password!</h3>
                <p class="reset-desc">Enter the email of your account to reset the password. Then you will receive a link to email to reset the password. If you have any issue about reset password <a routerLink="/contact-us">Contact Us.</a></p>
            </div>
            
            <form method="post">
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" type="text" name="name" placeholder="Enter Email Address">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-sm-6">
                        <a class="now-log-in font-q" routerLink="/my-account">Log in your account</a>
                    </div>
                    
                    <div class="col-lg-6 col-sm-6">
                        <p class="now-register">Not a member? <a class="font-q" routerLink="/my-account">Register</a></p>
                    </div>

                    <div class="col-lg-12 col-sm-12 text-center">
                        <button class="default-btn" type="submit">Reset Password</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Recover Password Area -->