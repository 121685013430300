<!-- Start Page Title Area -->
<div class="page-title-area bg-17">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="main-contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="contact-info">
                    <h3>Contact us, We Are Here to Help You</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    <ul class="address">
                        <li class="location">
                            <span>Our Office Address</span>
                            2364 Oakdale Avenue Clearwater, FL 34620, New York, USA
                        </li>
                        <li>
                            <span>Email</span>
                            <a href="mailto:info@visiondentalnow.com">info@visiondentalnow.com</a>
                            <a href="mailto:support@revor.com">support@revor.com</a>
                        </li>
                        <li>
                            <span>Phone</span>
                            <a href="tel:+1-(514)-312-6677">+1 (514) 312-6677</a>
                            <a href="tel:+1(514)-312-6688">+1 (514) 312-6688</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Email Address">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"  placeholder="Subject">
                                    </div>
                                </div>
    
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn"><span>Send Now</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->

<!-- End Map Area -->