<!-- Start Company Area -->
<section class="company-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="company-img">
                    <div class="trusted">
                        <span>100%</span>
                        <h3>Trusted Company</h3>
                        <p>You can depend on us for our company's services</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-content ptb-100">
                    <span class="top-title">Welcome to <strong>Revor</strong></span>
                    <h2>Since 25 Years, Our Company Has Been Working Very Faithfully</h2>
                    <p class="some-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/about-us" class="default-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Company Area -->