<!-- Start Future Area -->
<section class="future-area future-area-two mt-minus-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-trustworthy"></i>
                    <h3>Team of Professionals</h3>
                    <p>Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box active">
                    <i class="flaticon-saving"></i>
                    <h3>Affordable & Competitive Rates</h3>
                    <p>Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-future-box">
                    <i class="flaticon-cross"></i>
                    <h3>Superior Customer Service</h3>
                    <p>Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy Test Copy</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Future Area -->