<!-- Start Page Title Area -->
<div class="page-title-area bg-11">
    <div class="container">
        <div class="page-title-content">
            <h2>Dental Insurance</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Dental Insurance</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Car Insurance Area -->
<section class="car-insurance-area pt-100 pb-70">
    <div class="container">
        <div class="car-insurance-content">
            <div class="content step-1">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <h3>Dental Insurance?</h3>
                        <span>Insurance can help you save money.</span>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut laboriosam officia deleniti numquam, fugit qui, sint rerum esse eos sit provident ea. Inventore blanditiis nihil iure</p>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad provident id sequi repellendus accusamus maiores itaque, in impedit nam saepe ducimus magni error corrupti enim voluptate libero, perferendis, nobis architecto. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi commodi quos porro ratione obcaecati. Libero officiis nesciunt non temporibus, maxime optio voluptate nihil omni</p>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="car-insurance-img"></div>
                    </div>
                </div>
            </div>

            <div class="content step-2">
                <h3>What is Dental Insurance?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.</p>
            </div>

            <div class="content step-5">
                <h3>Types of Dental Insurance</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li><i class="flaticon-tick"></i> Professional Indemnity</li>
                            <li><i class="flaticon-tick"></i> Product Liability</li>
                            <li><i class="flaticon-tick"></i> Loss Prevention</li>
                        </ul>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li><i class="flaticon-tick"></i> Public Liability</li>
                            <li><i class="flaticon-tick"></i> Employers’ Liability</li>
                            <li><i class="flaticon-tick"></i> Risk Management</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="content step-4">
                <h3>Adding Value To Your Car Insurance Policy</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis sit amet, consectetur adipiscing elit, sed suspendisse ultrices.</p>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est ipsum dolor sit amet. Stet clita kasd gubergren, no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus tempor invidunt ut labore et dolore magna.</p>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="car-insurance-img-2">
                            <img src="assets/img/car-insurance-img-2.jpg" alt="Image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="car-insurance-img-3">
                            <img src="assets/img/car-insurance-img-3.jpg" alt="Image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="content step-6">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="faq-content">
                            <span class="top-title">Business Insurance FAQ, s</span>
                            <h2>You Can Learn a Lot From Our Questions Answer</h2>
                            <div class="faq-accordion mb-used">
                                <ul class="accordion">
                                    <li class="accordion-item">
                                        <a class="accordion-title active" href="javascript:void(0)">
                                            <i class="bx bx-plus"></i>
                                            What Is a Health Insurance Policy?
                                        </a>
                                        <div class="accordion-content show">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
                                        </div>
                                    </li>
                                    <li class="accordion-item">
                                        <a class="accordion-title" href="javascript:void(0)">
                                            <i class="bx bx-plus"></i>
                                            What do You Mean By Risk Management?
                                        </a>
                                        <div class="accordion-content">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
                                        </div>
                                    </li>
                                    <li class="accordion-item">
                                        <a class="accordion-title" href="javascript:void(0)">
                                            <i class="bx bx-plus"></i>
                                            Do I Need a Life Insurance Policy?
                                        </a>
                                        <div class="accordion-content">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
                                        </div>
                                    </li>
                                    <li class="accordion-item">
                                        <a class="accordion-title" href="javascript:void(0)">
                                            <i class="bx bx-plus"></i>
                                            Can I Choose Not to Get Business Insurance?
                                        </a>
                                        <div class="accordion-content">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
                                        </div>
                                    </li>
                                    <li class="accordion-item">
                                        <a class="accordion-title" href="javascript:void(0)">
                                            <i class="bx bx-plus"></i>
                                            How Do I Know What Deductible Applies to My Policy?
                                        </a>
                                        <div class="accordion-content">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
                                        </div>
                                    </li>
                                    <li class="accordion-item">
                                        <a class="accordion-title" href="javascript:void(0)">
                                            <i class="bx bx-plus"></i>
                                            What Is Personal Liability Umbrella Coverage?
                                        </a>
                                        <div class="accordion-content">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 p-0">
                        <div class="faq-img-three">
                            <img src="assets/img/faq-img-three.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Car Insurance Area -->