<!-- Start Page Title Area -->
<div class="page-title-area bg-14">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Grid</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Blog Grid</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-7.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li><i class="flaticon-user"></i> <a routerLink="/blog-grid">Jackson</a></li>
                            <li><i class="flaticon-calendar"></i> October 27, 2023</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Why Life Insurance Is a Force For Social Good</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                        <a routerLink="/blog-grid" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-9.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li><i class="flaticon-user"></i> <a routerLink="/blog-grid">Wiliyams</a></li>
                            <li><i class="flaticon-calendar"></i> October 29, 2023</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Global Trends in The Home Insurance Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-10.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li><i class="flaticon-user"></i> <a routerLink="/blog-grid">Juhon</a></li>
                            <li><i class="flaticon-calendar"></i> October 30, 2023</li>
                        </ul>
                        <h3><a routerLink="/blog-details">2023 Insurance Trends And Possible Challenges</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-11.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li><i class="flaticon-user"></i> <a routerLink="/blog-grid">Alex</a></li>
                            <li><i class="flaticon-calendar"></i> October 29, 2023</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Top Tips to Increase The Value of Your Home</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-12.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li><i class="flaticon-user"></i> <a routerLink="/blog-grid">Alex</a></li>
                            <li><i class="flaticon-calendar"></i> October 29, 2023</li>
                        </ul>
                        <h3><a routerLink="/blog-details">Insurance Industry Test Case Judgment</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-13.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="blog-content">
                        <ul>
                            <li><i class="flaticon-user"></i> <a routerLink="/blog-grid">Peterson</a></li>
                            <li><i class="flaticon-calendar"></i> October 28, 2023</li>
                        </ul>
                        <h3><a routerLink="/blog-details">5 Things to Know About Insurance Policy</a></h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog-grid" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog-grid" class="page-numbers">2</a>
                    <a routerLink="/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog-grid" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->