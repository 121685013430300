<!-- Start Hero Slider Area -->
<section class="hero-slider-area">
    <div class="hero-slider">
        <owl-carousel-o [options]="HeroSliderOptions">
            <ng-template carouselSlide>
                <div class="hero-slider-item bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container-fluid">
                                <div class="hero-slider-content">
                                    <span class="top-title">Our Main Purpose</span>
                                    <h1>Insuring Your Life, In The Easy Way</h1>
                                    <p>Ask us about our affordable Vision and Dental plans.</p>
                                    <div class="slider-btn">
                                        <a routerLink="/contact-us" class="default-btn">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="hero-slider-item bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container-fluid">
                                <div class="hero-slider-content">
                                    <span class="top-title">Our Main Purpose</span>
                                    <h1>Find the Right Care for You and Your family</h1>
                                    <p>Contact one of our team members today.</p>
                                    <div class="slider-btn">
                                        <a routerLink="/contact-us" class="default-btn">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="hero-slider-item bg-3">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container-fluid">
                                <div class="hero-slider-content">
                                    <span class="top-title">Our Main Purpose</span>
                                    <h1>Vision and Dental for the Self Employed</h1>
                                    <p>Contact us today to speak to one of our insurance professionals.</p>
                                    <div class="slider-btn">
                                        <a routerLink="/contact-us" class="default-btn">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <ul>
        <li><a href="#" target="_blank">Facebook</a></li>
        <li><a href="#" target="_blank">Instagram</a></li>
        <li><a href="#" target="_blank">Twitter</a></li>
        <li><a href="#" target="_blank">Linkedin</a></li>
    </ul>
</section>
<!-- End Hero Slide Area -->