<!-- Start Banner Area -->
<section class="banner-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <div class="banner-content">
                            <span class="wow fadeInDown" data-wow-delay="1s">Our Main Purpose</span>
                            <h1 class="wow fadeInDown" data-wow-delay="1s">Creating Savings Habits Among The People</h1>
                            <p class="wow fadeInLeft" data-wow-delay="1s">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
                            <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                <a routerLink="/contact-us" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="banner-img">
                            <img src="assets/img/banner/banner-img-1.jpg" alt="Image">
                            <div class="banner-shape-1">
                                <img src="assets/img/banner/banner-shape-1.png" alt="Image">
                            </div>
                            <ul>
                                <li><a href="#" target="_blank">Facebook</a></li>
                                <li><a href="#" target="_blank">Instagram</a></li>
                                <li><a href="#" target="_blank">Twitter</a></li>
                                <li><a href="#" target="_blank">Linkedin</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Banner Area -->